import { CLIENT_FEATURE_FLAGS } from '@/constants';
import { useFeatureFlags } from '../contexts/FeatureFlagsContext';

const useTestFlags = () => {
  const featureFlags = useFeatureFlags();
  const safetyBanner = featureFlags?.flags[CLIENT_FEATURE_FLAGS.SAFETY_BANNER];
  const navLoginLink = featureFlags?.flags[CLIENT_FEATURE_FLAGS.NAV_LOGIN_LINK]?.value ?? false;
  const vhpFontSelector = featureFlags?.flags[CLIENT_FEATURE_FLAGS.VHP_FONT_SELECTOR];
  const vhpVerticalHover = featureFlags?.flags[CLIENT_FEATURE_FLAGS.VHP_VERTICAL_HOVER];
  const seniorCareRedirection = featureFlags.flags[CLIENT_FEATURE_FLAGS.SC_PROVIDER_ENROLLMENT];
  const vhpTriageProviderVertical =
    featureFlags.flags[CLIENT_FEATURE_FLAGS.VHP_TRIAGE_PROVIDER_VERTICAL];
  const applyToJobsModal = featureFlags.flags[CLIENT_FEATURE_FLAGS.APPLY_TO_JOBS_MODAL];
  const vhpAllListYourBusinessCTA =
    featureFlags.flags[CLIENT_FEATURE_FLAGS.GROWTH_VHP_ALL_LIST_YOUR_BUSINESS_CTA];
  const rtxTrackingLazyLoad = featureFlags.flags[CLIENT_FEATURE_FLAGS.RTX_TRACKING_LAZY_LOAD];
  const adultCareTileTest = featureFlags.flags[CLIENT_FEATURE_FLAGS.GROWTH_VHP_ADULT_CARE_TILE];
  const providerHeader = featureFlags.flags[CLIENT_FEATURE_FLAGS.GROWTH_PROVIDER_HEADER];
  const plansPricing = featureFlags.flags[CLIENT_FEATURE_FLAGS.PLANS_PRICING];
  const seekerAdultCareExperience =
    featureFlags.flags[CLIENT_FEATURE_FLAGS.SEEKER_ADULT_CARE_EXPERIENCE];
  const iterableEmbeddedMessaging =
    featureFlags.flags[CLIENT_FEATURE_FLAGS.ITERABLE_EMBEDDED_MESSAGING_ENABLED];

  return {
    safetyBanner,
    navLoginLink,
    vhpFontSelector,
    vhpVerticalHover,
    seniorCareRedirection,
    applyToJobsModal,
    vhpTriageProviderVertical,
    vhpAllListYourBusinessCTA,
    rtxTrackingLazyLoad,
    adultCareTileTest,
    providerHeader,
    plansPricing,
    seekerAdultCareExperience,
    iterableEmbeddedMessaging,
  };
};

export default useTestFlags;
