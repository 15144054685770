var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"vhp-mfe@2.67.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/app/vhp";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { browserTracingIntegration } from '@sentry/nextjs';
import { WINDOW_SENTRY_TRACE_TRANSACTION_SAMPLING_RATES_KEY } from '@/constants';
import initSentry from './sentry.init';

initSentry({
  integrations: [
    browserTracingIntegration({
      enableInp: true,
    }),
  ],
  tracesSampleRate: window[WINDOW_SENTRY_TRACE_TRANSACTION_SAMPLING_RATES_KEY],
});
